.quiz-container {
    width: 100%;
    height: 100vh;
    background: url('../../../../public/images/utils/loading/long-loading.svg')
        no-repeat bottom / cover;
    font-family: 'Maple';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    .progress-bar {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100px;
        height: 30px;
        border-radius: 50px;
        .progress {
            border-radius: 50px;
        }
    }
    .clock {
        position: fixed;
        top: 50px;
        right: 140px;
        width: 100px;
        animation: swing 2s ease-in-out infinite;
        .count-down {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translateX(-50%);
            font-size: 32px;
            font-weight: bold;
            font-family: 'Maple';
            &.red {
                color: red;
            }
        }
        &::before {
            content: '남은시간';
            font-size: 18px;
            display: block;
            margin: 0.5rem auto;
            text-align: center;
            font-family: 'Maple';
        }
    }
    .music-play-btn {
        width: 120px;
        position: absolute;
        top: 70px;
        left: 140px;
        border: none;
        background: transparent;
        cursor: pointer;
        z-index: 9;
    }
    .inner {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .quiz-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .title {
            font-size: 3rem;
        }
        .quiz-title {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            gap: 2rem;
        }
        .quiz-main {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            figure {
                width: 28%;
                position: fixed;
                left: 10px;
                bottom: -100px;
            }
            .quiz-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .question {
                    margin: 2rem 0;
                    font-size: 2rem;
                    text-align: center;
                }
                .answer-buttons {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    .btn {
                        border: none;
                        background-color: var(--bs-white);
                        font-family: 'Maple';
                        font-weight: 900;
                        font-size: 9rem;
                        width: 300px;
                        height: 300px;
                        cursor: pointer;
                        &.btn-x {
                            color: #fd7e14;
                        }
                        &.btn-o {
                            color: #007bff;
                        }
                    }
                }
            }
        }
        .feedback {
            margin-top: 2rem;
        }
    }
}
