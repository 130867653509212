.series-book-list {
    .series-group {
        margin: 2rem 0;
        padding: 1rem;
        background-color: #ffdddd;
        border-radius: 1rem;
        &:nth-child(even) {
            background-color: #d1eac7;
        }
        .series-title {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        .series-books {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .book-cover {
                width: 32%;
            }
        }
    }
}
@media screen and (max-width: 1240px) {
    .series-book-list {
        .series-group {
            .series-books {
                .book-cover {
                    width: 30%;
                }
            }
        }
    }
}
