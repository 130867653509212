.book-cover {
    width: 32%;
    position: relative;
    border-radius: 0px 6.448px 6.448px 0px;
    overflow: hidden;
    cursor: pointer;
    transition-duration: 0.3s;
    .cover-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #f8f8f8; /* 플레이스홀더 배경색, 필요에 따라 변경 가능 */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1); /* 얇은 테두리 */
        border-top: 4px solid #3498db; /* 회전하는 부분의 색상 */
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &:hover {
        transform: translateY(-10px);
    }
    .grade-tag {
        position: absolute;
        top: 0;
        left: 20px;
        span {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--bs-white);
            font-weight: 900;
            font-size: 1.5rem;
        }
    }
    .best-title {
        position: absolute;
        bottom: 32px;
        left: 50%;
        transform: translateX(-50%);
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.73);
        color: var(--bs-white);
        font-weight: 700;
        text-align: center;
        width: 100%;
        font-size: 1.5rem;
        width: 90%;
        line-height: 1.4;
    }
    .book-shadow {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        width: 12px;
        height: 100%;
    }
    .book-white {
        border-radius: 1.612px;
        background: #fff;
        box-shadow: 0px 1.29px 1.29px 0px rgba(0, 0, 0, 0.25);
        width: 90%;
        height: 10px;
        position: absolute;
        bottom: 10px;
        right: 6px;
    }
}
