.modify-section {
    width: 100%;
    .inner {
        max-width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .modify-tit {
            width: 240px;
            margin-bottom: 1rem;
        }
        .modify-box {
            background: #ea8a37;
            width: 80%;
            border-radius: 26px;
            padding: 1rem;
        }
        .modify-container {
            width: 100%;
            height: 100%;
            background-color: var(--bs-white);
            border-radius: 32px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .modify {
                width: 100%;
                height: 100%;
                padding: 1.5rem;
                .title {
                }
                &.left {
                    background-color: #fdf2bd;
                }
                &.right {
                    .modify-area {
                        margin-top: 1rem;
                        width: 100%;
                        height: 90%;
                        border: none;
                        font-family: 'Maple';
                        color: #8c8b8b;
                        font-size: 1.5rem;
                    }
                }
            }
        }
        .btn-container {
            .confirm-btn {
                position: relative;
                margin-top: 1rem;
                cursor: pointer;
                .modify-text {
                    color: var(--bs-white);
                    font-weight: 900;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.25rem;
                }
            }
            .close-btn {
                position: relative;
                margin-top: 1rem;
                cursor: pointer;
                .modify-text {
                    color: var(--bs-white);
                    font-weight: 900;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.25rem;
                }
            }
        }
    }
}
