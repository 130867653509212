.loading-section {
    width: 100%;
    height: 100vh;
    background: url('../../../../public/images/utils/loading/bg-black.svg')
        no-repeat center / cover;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    .music-play-btn {
        width: 120px;
        position: absolute;
        top: 70px;
        left: 240px;
        border: none;
        background: transparent;
        cursor: pointer;
        z-index: 9;
    }
    .loading-bar-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
        gap: 1.5rem;
    }
    .loading-title {
        font-size: 2.5rem;
        font-weight: bold;
        color: var(--bs-white);
        font-family: 'Maple';
    }
    .loading-title.on {
        color: #b5b5b573;
    }
    .loading-title.black {
        color: #111;
    }
    .loading-title-sub {
        font-size: 2.5rem;
        font-weight: bold;
        color: #fff;
        margin-top: -10px;
        font-family: 'Maple';
    }
    .loading-title-sub.on {
        color: #b5b5b573;
    }
    .loading-title-sub.none {
        display: none;
    }
    .sub-txt {
        color: var(--bs-white);
        font-size: 1.25rem;
        opacity: 0.9;
        font-family: 'Maple';
    }
    .sub-txt.none {
        display: none;
    }
    .tree-left {
        position: absolute;
        left: -50px;
        bottom: -80px;
    }
    .tree-left.none {
        display: none;
    }
    .tree-right {
        position: absolute;
        right: -50px;
        bottom: -60px;
        width: 480px;
    }
    .tree-right.none {
        display: none;
    }
    .left-top {
        position: absolute;
        left: -30px;
        top: 90px;
        width: 410px;
    }
    .left-top.none {
        display: none;
    }

    .right-top {
        position: absolute;
        right: -50px;
        top: 50px;
    }
    .right-top.none {
        display: none;
    }

    .star-1 {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 200px;
        bottom: 500px;
        animation: starrr 2s ease infinite alternate;
    }

    .clock {
        position: fixed;
        top: 50px;
        right: 250px;
        width: 100px;
        animation: swing 2s ease-in-out infinite;
        .count-down {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translateX(-50%);
            font-size: 32px;
            font-family: 'Maple';
            &.red {
                color: red;
            }
        }
        &::before {
            content: '남은시간';
            font-family: 'Maple';
            font-size: 18px;
            display: block;
            margin: 0.5rem auto;
            text-align: center;
            color: var(--bs-white);
        }
    }
    @keyframes starrr {
        0% {
            transform: rotate(180deg);
        }
        100% {
            opacity: 0;
        }
    }
    .star-2 {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 50px;
        top: 200px;
        animation: starrr 2s ease infinite alternate;
    }
    .animation {
        animation: test 1s ease infinite alternate;
    }
    .star-3 {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 200px;
        top: 100px;
    }
    @keyframes test {
        100% {
            transform: rotate(1deg);
            transform-origin: right bottom;
        }
    }
    .sparkle {
        animation: star 0.8s ease infinite alternate;
    }
    @keyframes star {
        0% {
            transform: rotate(180deg) scale(0);
            opacity: 0;
            transform-origin: center;
        }
        100% {
            transform: rotate(10deg) scale(1);
            opacity: 1;
            transform-origin: center;
        }
    }

    .bubble-group {
        animation: bubble 3s ease infinite alternate;
    }

    @keyframes bubble {
        0% {
            opacity: 0;
            transform-origin: center;
        }
        100% {
            opacity: 1;
            transform-origin: center;
        }
    }

    .bar {
        animation: bar-rotate 1s ease infinite alternate;
    }
    @keyframes bar-rotate {
        0% {
            transform: rotate(0.2deg);
            transform-origin: center;
        }
        100% {
            transform: rotate(-0.2deg);
            transform-origin: center;
        }
    }
    .book {
        animation: book 1s ease infinite alternate;
    }
    @keyframes book {
        0% {
            transform: rotate(10deg);
            transform-origin: center;
        }
        100% {
            transform: rotate(0deg);
            transform-origin: center;
        }
    }
    .book:hover {
        animation-play-state: paused;
    }
    .witch {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -210px;
    }
    .story-book {
        position: absolute;
        left: 50%;
        bottom: 70px;
        transform: translateX(-50%);
        width: 50%;
    }
    .brow {
        animation: brow 2s ease infinite alternate;
    }
    @keyframes brow {
        0% {
            transform: rotate(1deg);
            transform-origin: top center;
        }
        100% {
            transform: rotate(0deg);
            transform-origin: top center;
        }
    }
    .circle {
        animation: circle 0.9s ease infinite alternate;
    }
    @keyframes circle {
        0% {
            opacity: 0.3;
        }
        100% {
            opacity: 0.1;
        }
    }
    .star-light {
        position: absolute;
        right: 280px;
        top: 90px;
        z-index: 0;
        display: none;
    }
    .star-light.none {
        display: none;
    }
    @keyframes diagonalMove {
        0% {
            transform: translateY(0) translateX(0);
            opacity: 0;
        }
        50% {
            transform: translateY(-100px) translateX(100px);
        }
        100% {
            transform: translateY(0) translateX(0);
        }
    }
}
.loading-section.background-changed {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    background: url('../../../../public/images/utils/loading/loading-bg.svg')
        no-repeat center / cover;
}
// mediaQuery
// 2024-02-16 | 미디어쿼리 추가
@media screen and (min-width: 1441px) and (max-width: 1540px) {
    .loading-section {
        .witch {
            bottom: -275px;
            .witch-svg {
                width: 650px;
            }
        }
        .tree-left {
            width: 25%;
        }
        .tree-right {
            width: 25%;
        }
    }
}
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .loading-section {
        .witch {
            bottom: -290px;
            .witch-svg {
                width: 620px;
            }
        }
        .tree-left {
            width: 25%;
        }
        .tree-right {
            width: 25%;
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .loading-section {
        .loading-bar-container {
            position: relative;
            z-index: 9;
        }
        .story-book {
            position: absolute;
            left: 50%;
            bottom: 70px;
            transform: translateX(-50%);
            width: 70%;
            position: absolute;
            z-index: 9;
        }
        .left-top {
            width: 210px;
        }
        .right-top {
            width: 230px;
        }
        .tree-left {
            width: 260px;
        }
        .tree-right {
            width: 260px;
        }
        .witch {
            bottom: -290px;
        }
        .witch-svg {
            width: 620px;
        }
        .star-light {
            width: 100px;
            right: 130px;
        }
        .clock {
            right: 70px;
            z-index: 99;
        }
        .music-play-btn {
            top: -10px;
            left: 120px;
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .loading-section {
        .loading-bar-container {
            position: relative;
            z-index: 9;
        }
        .story-book {
            position: absolute;
            left: 50%;
            bottom: 70px;
            transform: translateX(-50%);
            width: 70%;
            position: absolute;
            z-index: 9;
        }
        .left-top {
            width: 210px;
        }
        .right-top {
            width: 230px;
        }
        .tree-left {
            width: 260px;
        }
        .tree-right {
            width: 260px;
        }
        .witch {
            bottom: -338px;
        }
        .witch-svg {
            width: 450px;
        }
        .star-light {
            width: 100px;
            right: 130px;
        }
        .clock {
            right: 70px;
            z-index: 99;
            width: 80px;
        }
        .music-play-btn {
            top: -10px;
            left: 80px;
            width: 100px;
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .loading-section {
        .loading-bar-container {
            position: relative;
            z-index: 9;
        }
        .story-book {
            position: absolute;
            left: 50%;
            bottom: 70px;
            transform: translateX(-50%);
            width: 70%;
            position: absolute;
            z-index: 9;
        }
        .left-top {
            width: 210px;
        }
        .right-top {
            width: 230px;
        }
        .tree-left {
            width: 260px;
        }
        .tree-right {
            width: 260px;
        }
        .witch {
            bottom: -338px;
        }
        .witch-svg {
            width: 450px;
        }
        .star-light {
            width: 100px;
            right: 130px;
        }
        .clock {
            right: 70px;
            z-index: 99;
            width: 80px;
        }
        .music-play-btn {
            top: -10px;
            left: 80px;
            width: 100px;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .loading-section {
        .loading-bar-container {
            position: relative;
            z-index: 9;
        }
        .story-book {
            position: absolute;
            left: 50%;
            bottom: 70px;
            transform: translateX(-50%);
            width: 70%;
            position: absolute;
            z-index: 9;
        }
        .left-top {
            width: 210px;
        }
        .right-top {
            width: 230px;
        }
        .tree-left {
            width: 260px;
        }
        .tree-right {
            width: 260px;
        }
        .witch {
            bottom: -338px;
        }
        .witch-svg {
            width: 450px;
        }
        .star-light {
            width: 100px;
            right: 130px;
        }
        .clock {
            right: 70px;
            z-index: 99;
            width: 80px;
        }
        .music-play-btn {
            top: -10px;
            left: 80px;
            width: 100px;
        }
    }
}
// 2024-02-06 | 미디어쿼리 추가
@media screen and (max-width: 767px) {
    .loading-section {
        .loading-bar-container {
            position: relative;
            z-index: 9;
        }
        .story-book {
            position: absolute;
            left: 50%;
            bottom: 70px;
            transform: translateX(-50%);
            width: 70%;
            position: absolute;
            z-index: 9;
        }
        .left-top {
            width: 210px;
        }
        .right-top {
            width: 230px;
        }
        .tree-left {
            width: 260px;
        }
        .tree-right {
            width: 260px;
        }
        .witch {
            bottom: -338px;
        }
        .witch-svg {
            width: 450px;
        }
        .star-light {
            width: 100px;
            right: 130px;
        }
        .clock {
            right: 70px;
            z-index: 99;
            width: 80px;
        }
        .music-play-btn {
            top: -10px;
            left: 80px;
            width: 100px;
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .loading-section {
        .clock {
            top: 130px;
        }
        .progress-bar {
            width: 70%;
            height: 40px;
        }
        .music-play-btn {
            top: -150px;
        }
        .loading-bar-container {
            margin-top: 0;
            top: 30%;
        }
        .sub-txt {
            font-size: 1.5rem;
        }
        .witch {
            bottom: -288px;
            .witch-svg {
                width: 630px;
            }
        }
    }
}
