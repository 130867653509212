.home-wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #fdf2bd;
    position: relative;
    .inner {
        max-width: 90%;
        padding: 7rem 0;
        .seller-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            display: none;
            & > * {
                width: 47%;
                .title {
                    display: inline-flex;
                    align-items: center;
                    background-color: #b0561d;
                    border-radius: 12px 12px 0px 0px;
                    color: var(--bs-white);
                    font-size: 2rem;
                    padding: 1rem;
                    span {
                        color: #fcc92c;
                        padding-left: 0.5rem;
                    }
                }
                .container {
                    width: 100%;
                    background-color: #b0561d;
                    border-radius: 0px 12px 12px 12px;
                    padding: 0.5rem;
                    .container-bg {
                        border-radius: 12px;
                        background: #fcf9ed;
                        padding: 1rem;
                        .more-view-btn {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 0.25rem;
                            cursor: pointer;
                            p {
                                text-align: right;
                                font-size: 1.15rem;
                            }
                            .plus-icon {
                                display: inline-flex;
                                width: 24px;
                                height: 24px;
                                background-color: #3f1a0a;
                                border-radius: 50%;
                                align-items: center;
                                justify-content: center;
                                color: var(--bs-white);
                            }
                        }
                        .box-conts {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 1rem;
                        }
                    }
                }
            }
            .create-best {
                width: 47%;
                .title {
                    .light-logo {
                        margin-right: 0.25rem;
                    }
                }
            }
        }
        .experience-title {
            width: 280px;
            margin: 40px auto;
        }
        .experience-container {
            position: relative;
            z-index: 10;
            .book-icon {
                position: absolute;
                cursor: pointer;
                width: 35%;
                transition-duration: 0.3s;
                &:hover {
                    animation: move-up 0.5s infinite ease-in alternate;
                }
                .title-figure {
                    width: 75%;
                    margin: 0 auto;
                }
                &.icon-1 {
                    top: -100px;
                    left: 0;
                    .title-figure {
                        width: 55%;
                    }
                }
                &.icon-2 {
                    bottom: 80px;
                    left: 50%;
                    transform: translateX(-50%);
                    .title-figure {
                        width: 60%;
                    }
                    &:hover {
                        animation: moving 0.5s infinite ease-in alternate;
                    }
                }
                &.icon-3 {
                    right: 100px;
                    top: -100px;
                    width: 23%;
                }
            }
        }
    }
    .shelf {
        position: absolute;
        bottom: 99px;
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
    }
    .bottom {
        width: 100%;
        height: 100px;
        background: #503925;
        position: relative;
        z-index: 10;
    }
}
@media screen and (max-width: 1400px) {
    .home-wrapper {
        .inner {
            padding: 5rem 0;
        }
        .shelf {
            width: 130px;
        }
    }
}
@media screen and (max-width: 1025px) {
    .home-wrapper {
        .inner {
            padding: 7rem 0;
            .seller-container {
                flex-direction: column;
                gap: 1rem;
                & > * {
                    width: 100%;
                    .title {
                        font-size: 1.4rem;
                    }
                }
                .create-best {
                    width: 100%;
                }
            }
            .experience-title {
                margin: 60px auto 40px;
            }
        }
        .shelf {
            width: 100px;
            bottom: 59px;
        }
        .bottom {
            height: 60px;
        }
    }
}
@keyframes move-up {
    100% {
        transform: translateY(-20px);
    }
}
@keyframes moving {
    0% {
        bottom: 80px;
    }
    100% {
        bottom: 100px;
    }
}
