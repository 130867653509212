.final-modal-section {
    width: 100%;
    height: 100vh;
    background: #000 url(../../../../public/images/utils/modal/final-bg.svg)
        no-repeat center / cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    .loader {
        border: 4px solid #f3f3f3;
        border-top: 4px solid var(--bs-green-light);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        margin-bottom: 1.5rem;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .sub-title {
        color: var(--bs-white);
        margin-bottom: 3rem;
        opacity: 0.8;
        font-size: 1.25rem;
        text-align: center;
        line-height: 1.2;
        font-family: 'Maple';
    }
    .title-completed-notice {
        font-family: 'Maple';
        margin-bottom: 1rem;
        font-size: 2rem;
        color: #f9ac00;
    }
    .final-title,
    .cover-instruction-notice {
        text-align: center;
        font-size: 4rem;
        font-weight: bold;
        color: var(--bs-white);
        margin-bottom: 1.5rem;
        -webkit-text-stroke: 1px #000;
        text-shadow: 3px 3px rgba(0, 0, 0, 0.8);
        font-family: 'Maple';
        -webkit-text-stroke: 4px var(--bs-green-light);
        animation: blink 2s linear infinite;
        &.sucess {
            animation: none;
            color: #fbc346;
            -webkit-text-stroke: 0px var(--bs-green-light);
        }
    }
    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
    .choose-btn-container {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }
    .make-buttons {
        padding: 1rem 2rem;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        font-family: 'Maple';
        font-size: 36px;
    }
    .complete {
        padding: 1rem 7.5rem;
        margin-top: 1rem;
        border: none;
        border-radius: 0.5rem;
        background-color: rgba($color: #000000, $alpha: 0.5);
        color: rgba($color: #000000, $alpha: 0.5);
        font-weight: bold;
        cursor: pointer;
        font-family: 'Maple';
        font-size: 32px;
        width: 100%;
    }
    // 2024-01-24 | 동화완성 버튼 깜빡깜빡 처리 93 ~102
    .complete.on {
        background-color: #ffc377;
        color: #000;
        animation: bg-change 0.5s infinite alternate;
    }
    @keyframes bg-change {
        100% {
            background-color: #f9ac00;
        }
    }
    .container-flex.reverse {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        .complete {
            opacity: 0.9;
        }
        .choose-btn-container {
            margin-top: 0;
            button {
                height: 70px;
                opacity: 0.9;
            }
        }
    }
    .go-back {
        padding: 1rem;
        position: absolute;
        left: 20px;
        top: 100px;
        font-family: 'Maple';
        font-size: 1.2rem;
        border-radius: 4px;
        background-color: lightgray;
        opacity: 0.9;
        cursor: pointer;
    }
}
// tab-미디어쿼리 (임시)
@media (min-width: 600px) and (max-width: 1200px) {
    .final-modal-section {
        .sub-title {
            font-size: 2.25rem;
        }
        .make-buttons {
            width: 360px;
            height: 100px;
            font-size: 36px;
        }
        .complete {
            width: 740px;
            height: 100px;
            font-size: 36px;
        }
    }
}
