.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    button {
        border: none;
        background: none;
        font-size: 1.25rem;
        font-family: 'Maple';
        cursor: pointer;
    }
}
