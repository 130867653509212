.shortloading-section {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    .inner {
        max-width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .loading-title {
            color: #fcc92c;
            text-align: center;
            font-weight: 900;
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        .loading-char {
            width: 25%;
            margin-top: 2rem;
        }
        .loading-desc {
            color: var(--bs-white);
            line-height: 1.6;
            font-size: 1.25rem;
            text-align: center;
            margin-top: 3rem;
        }
    }
}
