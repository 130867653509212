.mystorybook-section {
    background-color: #f3e6e7;
    width: 100%;
    min-height: 100vh;
    position: relative;
    .inner {
        max-width: 90%;
        padding: 6rem 0 3rem;
        position: relative;
        .all-view-btn {
            width: 140px;
            position: absolute;
            right: 0;
            cursor: pointer;
        }
        .storybook-tit {
            width: 200px;
            margin: 0 auto;
        }
        .mybook-container {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 1rem;
            .mybook-box {
                background-color: var(--bs-white);
                padding: 1rem;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 18px;
                &.left {
                    width: 19%;
                    padding-top: 4rem;
                    .category-list {
                        li {
                            &.active {
                                color: #fe8498;
                                background: #f3e6e7;
                            }
                        }
                    }
                }
                &.right {
                    width: 80%;
                    .box-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        p {
                            color: #525252;
                            font-size: 1.15rem;
                        }
                        select {
                            font-family: 'Maple';
                            font-size: 16px;
                            padding: 10px 26px 10px 10px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            background: url('../../../public/images/utils/polygon.svg')
                                no-repeat right 10px center;
                            background-size: 12px;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            cursor: pointer;
                            color: #d9d9d9;
                        }
                    }
                    .box-conts {
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 1.25rem;
                        flex-wrap: wrap;
                        .book-cover {
                            // width: 23%;
                            // flex-shrink: 0;
                            flex: 0 0 calc((100% - (3 * 1.25rem)) / 4);
                        }
                    }
                }
            }
        }
    }
    .navigate-char {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 300px;
        z-index: 11;
    }
}
@media screen and (max-width: 1439px) {
    .mystorybook-section {
        .inner {
            .mybook-container {
                .mybook-box.right {
                    .box-conts {
                        .book-cover {
                            flex: 0 0 calc((100% - (2 * 1.25rem)) / 3);
                        }
                    }
                }
            }
        }
        .navigate-char {
            width: 260px;
        }
    }
}
@media screen and (max-width: 1025px) {
    .mystorybook-section {
        .inner {
            padding: 6rem 0 4rem;
            .torybook-tit {
                width: 180px;
            }
            .mybook-container {
                .mybook-box.right {
                    .box-conts {
                        .book-cover {
                            flex: 0 0 calc((100% - (1 * 1.25rem)) / 2);
                        }
                    }
                }
            }
        }
        .navigate-char {
            width: 240px;
        }
    }
}
