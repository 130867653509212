.category-list {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    li {
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        color: #b2b2b2;
        font-size: 1.25rem;
        cursor: pointer;
        &.active {
            border-radius: 8px;
            background: #fdf2bd;
            color: #fda61c;
            padding-top: 1rem;
            border-bottom: none;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
