.progress-bar {
    width: 40%;
    height: 24px;
    background-color: #d9d9d9;
    border-radius: 24px;
    margin: 0 auto;
    overflow: hidden;
    .progress {
        height: 100%;
        background-color: #90b4e0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: width 0.6s;
        font-family: 'Maple';
        border-radius: 24px;
    }
}
