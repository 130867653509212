.books-section {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: #f7f6f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    .music-play-btn {
        width: 120px;
        position: fixed;
        top: 40px;
        left: 240px;
        border: none;
        background: transparent;
        cursor: pointer;
    }
    .bookloading-title {
        font-size: 32px;
        font-family: 'Maple';
        margin-bottom: 1rem;
        opacity: 0.2;
    }
    .bookloading-title.on {
        color: var(--bs-green-light);
        opacity: 1;
        font-size: 40px;
    }
    .bookloading-title-sub {
        font-size: 32px;
        font-family: 'Maple';
        opacity: 0.2;
    }
    .bookloading-title-sub.on {
        color: var(--bs-green-light);
        opacity: 1;
        font-size: 40px;
    }
    .progress-bar {
        margin: 2rem 0;
        height: 24px;
        border-radius: 3rem;
        .progress {
            border-radius: 3rem;
            font-family: 'Maple';
        }
    }
    .clock {
        position: fixed;
        top: 40px;
        right: 250px;
        width: 100px;
        animation: swing 2s ease-in-out infinite;
        .count-down {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translateX(-50%);
            font-size: 32px;
            font-family: 'Maple';
            &.red {
                color: red;
            }
        }
        &::before {
            content: '남은시간';
            font-family: 'Maple';
            font-size: 18px;
            display: block;
            margin: 0.5rem auto;
            text-align: center;
        }
    }
    .waiting-txt {
        font-family: 'Maple';
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
        line-height: 1.4;
    }
    .storyland-container {
        position: relative;
        width: 65%;
        margin: 0 auto;
        .storyland-book-text {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            font-family: 'Maple';
            font-size: 2rem;
            width: 85%;
            text-align: center;
            color: #000;
            line-height: 1.4;
            .bg-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 8px #fff;
                z-index: -1;
                line-height: 1.4;
            }
        }
        .book-container {
            position: relative;
            width: 100%;
            height: 520px;
            overflow: hidden;
        }
        .page {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .chat-bubble {
        width: 200px;
        position: absolute;
        left: 20px;
        bottom: 240px;
        animation: brow-move 1s infinite alternate;
    }
    .girl-left {
        width: 16%;
        position: fixed;
        left: 30px;
        bottom: 0;
        .girl-svg {
            width: 240px;
        }
    }
    .boy-right {
        width: 17%;
        position: fixed;
        right: 40px;
        bottom: -10px;
        figure {
            width: 360px;
        }
        .boy-svg {
            width: 280px;
        }
    }
    .shelf {
        position: fixed;
        bottom: 0;
        width: 10%;
        &.book-left {
            left: -10px;
        }
        &.book-right {
            right: -10px;
        }
    }
    .btn-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 65%;
        .btn {
            width: 50%;
            padding: 0.5rem;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            cursor: pointer;
            border-radius: 4px;
            background-color: #9adbc5;
            figure {
                background-color: #31a78f;
                width: 30px;
                height: 30px;
                padding: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
            span {
                color: #204743;
                font-family: 'Maple';
                font-size: 24px;
            }
        }
    }
    &.background-changed {
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 999;
        overflow: hidden;
        background: url('../../../../public/images/utils/loading/loading-bg.svg')
            no-repeat center / cover;
    }
    .arm {
        animation: move 1s infinite alternate;
    }
    @keyframes move {
        100% {
            transform: rotate(1deg);
        }
    }
    .brow {
        animation: brow-move 1s infinite alternate;
    }
    @keyframes brow-move {
        100% {
            transform: translateY(-2px);
        }
    }
}
// mediaQuery
// 2024-02-16 | 미디어쿼리 추가
@media screen and (min-width: 1441px) and (max-width: 1540px) {
    .books-section {
        .bookloading-title {
            font-size: 28px;
            &.on {
                font-size: 32px;
            }
        }
        .bookloading-title-sub {
            font-size: 28px;
            &.on {
                font-size: 32px;
            }
        }
        .waiting-txt {
            font-size: 1.2rem;
        }
        .progress-bar {
            height: 20px;
            margin: 1rem 0;
        }
        .storyland-container {
            width: 70%;
            .book-container {
                width: 100%;
                height: 460px;
            }
        }
        .btn-container {
            width: 70%;
            .btn {
                padding: 0.6rem;
                span {
                    font-size: 18px;
                }
            }
        }
        .music-play-btn {
            left: 60px;
        }
        .clock {
            right: 80px;
        }
        .shelf {
            width: 7%;
        }
        .chat-bubble {
            width: 200px;
            left: 10px;
            bottom: 200px;
        }
        .girl-left {
            left: 40px;
            bottom: 0px;
        }
        .girl-svg {
            width: 200px;
        }
        .boy-right {
            right: 10px;
            bottom: -10px;
        }
        .boy-svg {
            width: 250px;
        }
    }
}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .books-section {
        .bookloading-title {
            font-size: 28px;
            &.on {
                font-size: 32px;
            }
        }
        .bookloading-title-sub {
            font-size: 28px;
            &.on {
                font-size: 32px;
            }
        }
        .waiting-txt {
            font-size: 1.2rem;
        }
        .progress-bar {
            height: 20px;
            margin: 1rem 0;
        }
        .storyland-container {
            width: 70%;
            .book-container {
                width: 100%;
                height: 400px;
            }
        }
        .btn-container {
            width: 70%;
            .btn {
                padding: 0.6rem;
                span {
                    font-size: 18px;
                }
            }
        }
        .music-play-btn {
            left: 60px;
        }
        .clock {
            right: 80px;
        }
        .shelf {
            width: 7%;
        }
        .chat-bubble {
            width: 200px;
            left: 10px;
            bottom: 200px;
        }
        .girl-left {
            left: 40px;
            bottom: 0px;
        }
        .girl-svg {
            width: 200px;
        }
        .boy-right {
            right: 10px;
            bottom: -10px;
        }
        .boy-svg {
            width: 250px;
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .books-section {
        .shelf {
            width: 8%;
        }
        .waiting-txt {
            font-size: 1rem;
        }
        .bookloading-title {
            font-size: 28px;
            &.on {
                font-size: 32px;
            }
        }
        .bookloading-title-sub {
            font-size: 28px;
            &.on {
                font-size: 32px;
            }
        }
        .btn-container {
            .btn {
                span {
                    font-size: 20px;
                }
            }
        }
        .storyland-container {
            width: 80%;
            .book-container {
                width: 100%;
                margin: 0 auto;
                height: 390px;
            }
        }
        .progress-bar {
            margin: 1rem 0;
            height: 20px;
        }
        .music-play-btn {
            top: 40px;
            left: 30px;
        }
        .progress-bar {
            width: 40%;
        }
        .clock {
            top: 40px;
            right: 40px;
        }
        .chat-bubble {
            width: 160px;
            bottom: 190px;
            left: 10px;
        }
        .girl-left {
            bottom: 0px;
            left: 50px;
        }
        .girl-svg {
            width: 200px;
        }
        .boy-right {
            right: -10px;
            bottom: -10px;
        }
        .boy-svg {
            width: 230px;
        }
        .btn-container {
            width: 80%;
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .books-section {
        .progress-bar {
            height: 20px;
            margin: 1rem 0;
        }
        .storyland-container {
            width: 100%;
        }
        .bookloading-title {
            font-size: 24px;
            font-family: 'Maple';
            margin-bottom: 1rem;
            opacity: 0.2;
        }
        .bookloading-title.on {
            color: var(--bs-green-light);
            opacity: 1;
            font-size: 34px;
        }
        .bookloading-title-sub {
            font-size: 24px;
        }
        .shelf {
            width: 6%;
        }
        .storyland-container {
            .book-container {
                width: 75%;
                height: 300px;
                margin: 0 auto;
            }
            .storyland-book-text {
                width: 70%;
                bottom: 10px;
                font-size: 1.8rem;
            }
        }
        .music-play-btn {
            width: 100px;
            top: 40px;
            left: 30px;
        }
        .progress-bar {
            width: 40%;
        }
        .clock {
            width: 65px;
            top: 40px;
            right: 40px;
        }
        .waiting-txt {
            font-family: 'Maple';
            font-size: 1.25rem;
            margin-bottom: 1rem;
            text-align: center;
            line-height: 1.4;
        }
        .chat-bubble {
            width: 100px;
            bottom: 140px;
            left: 10px;
        }
        .girl-left {
            bottom: 0px;
            left: 10px;
            .girl-svg {
                width: 140px;
            }
        }

        .boy-right {
            bottom: -10px;
        }
        .btn-container {
            width: 75%;
            .btn {
                padding: 0.5rem;
                span {
                    font-size: 20px;
                }
            }
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .books-section {
        .progress-bar {
            height: 20px;
            margin: 1rem 0;
        }
        .storyland-container {
            width: 100%;
        }
        .bookloading-title {
            font-size: 24px;
            font-family: 'Maple';
            margin-bottom: 1rem;
            opacity: 0.2;
        }
        .bookloading-title.on {
            color: var(--bs-green-light);
            opacity: 1;
            font-size: 34px;
        }
        .bookloading-title-sub {
            font-size: 24px;
        }
        .shelf {
            width: 6%;
        }
        .storyland-container {
            .book-container {
                width: 75%;
                height: 300px;
                margin: 0 auto;
            }
            .storyland-book-text {
                width: 70%;
                bottom: 10px;
                font-size: 1.8rem;
            }
        }
        .music-play-btn {
            width: 100px;
            top: 40px;
            left: 30px;
        }
        .progress-bar {
            width: 40%;
        }
        .clock {
            width: 65px;
            top: 40px;
            right: 40px;
        }
        .waiting-txt {
            font-family: 'Maple';
            font-size: 1.25rem;
            margin-bottom: 1rem;
            text-align: center;
            line-height: 1.4;
        }
        .chat-bubble {
            width: 100px;
            bottom: 140px;
            left: 10px;
        }
        .girl-left {
            bottom: 0px;
            left: 10px;
        }
        .girl-svg {
            width: 140px;
        }
        .boy-right {
            bottom: -10px;
        }
        .btn-container {
            width: 75%;
            .btn {
                padding: 0.5rem;
                span {
                    font-size: 20px;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .books-section {
        .progress-bar {
            height: 20px;
            margin: 1rem 0;
        }
        .storyland-container {
            width: 100%;
        }
        .bookloading-title {
            font-size: 24px;
            font-family: 'Maple';
            margin-bottom: 1rem;
            opacity: 0.2;
        }
        .bookloading-title.on {
            color: var(--bs-green-light);
            opacity: 1;
            font-size: 34px;
        }
        .bookloading-title-sub {
            font-size: 24px;
        }
        .shelf {
            width: 6%;
        }
        .storyland-container {
            .book-container {
                width: 75%;
                height: 300px;
                margin: 0 auto;
            }
            .storyland-book-text {
                width: 70%;
                bottom: 10px;
                font-size: 1.8rem;
            }
        }
        .music-play-btn {
            width: 100px;
            top: 40px;
            left: 30px;
        }
        .progress-bar {
            width: 40%;
        }
        .clock {
            width: 65px;
            top: 40px;
            right: 40px;
        }
        .waiting-txt {
            font-family: 'Maple';
            font-size: 1.25rem;
            margin-bottom: 1rem;
            text-align: center;
            line-height: 1.4;
        }
        .chat-bubble {
            width: 100px;
            bottom: 140px;
            left: 10px;
        }
        .girl-left {
            bottom: 0px;
            left: 10px;
        }
        .girl-svg {
            width: 140px;
        }
        .boy-right {
            bottom: -10px;
        }
        .btn-container {
            width: 75%;
            .btn {
                padding: 0.5rem;
                span {
                    font-size: 20px;
                }
            }
        }
    }
}
// 2024-02-06 | 미디어쿼리 추가
@media screen and (max-width: 767px) {
    .books-section {
        .progress-bar {
            height: 20px;
            margin: 1rem 0;
        }
        .storyland-container {
            width: 100%;
        }
        .bookloading-title {
            font-size: 24px;
            font-family: 'Maple';
            margin-bottom: 1rem;
            opacity: 0.2;
        }
        .bookloading-title.on {
            color: var(--bs-green-light);
            opacity: 1;
            font-size: 34px;
        }
        .bookloading-title-sub {
            font-size: 24px;
        }
        .shelf {
            width: 6%;
        }
        .storyland-container {
            .book-container {
                width: 75%;
                height: 300px;
                margin: 0 auto;
            }
            .storyland-book-text {
                width: 70%;
                bottom: 10px;
                font-size: 1.8rem;
            }
        }
        .music-play-btn {
            width: 100px;
            top: 40px;
            left: 30px;
        }
        .progress-bar {
            width: 40%;
        }
        .clock {
            width: 65px;
            right: 40px;
        }
        .waiting-txt {
            font-family: 'Maple';
            font-size: 1.25rem;
            margin-bottom: 1rem;
            text-align: center;
            line-height: 1.4;
        }
        .chat-bubble {
            width: 100px;
            bottom: 140px;
            left: 10px;
        }
        .girl-left {
            bottom: 0px;
            left: 10px;
        }
        .girl-svg {
            width: 140px;
        }
        .boy-right {
            bottom: -10px;
        }
        .btn-container {
            width: 75%;
            .btn {
                padding: 0.5rem;
                span {
                    font-size: 20px;
                }
            }
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .books-section {
        .bookloading-title {
        }
        .storyland-container {
            width: 90%;
            .book-container {
                width: 100%;
            }
        }
        .btn-container {
            width: 90%;
            .btn {
                padding: 1rem;
            }
        }
        .shelf {
            width: 9%;
        }
        .girl-left {
            left: 180px;
            bottom: 0px;
            .girl-svg {
                width: 160px;
            }
        }
        .chat-bubble {
            left: 130px;
        }
        .boy-right {
            display: block;
            right: 210px;
            bottom: -10px;
            .boy-svg {
                width: 250px;
            }
        }
    }
}
