.all-view-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    .inner {
        max-width: 90%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        .title {
            width: 400px;
        }
        .close-btn {
            width: 60px;
            margin-left: auto;
            cursor: pointer;
        }
        .all-view-container {
            width: 90%;
            padding: 1rem;
            background-color: #ea8a37;
            padding: 1rem;
            max-height: 600px;
            border-radius: 24px;
            position: relative;
            .all-view-list {
                padding: 1rem;
                height: 100%;
                background-color: var(--bs-white);
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 2rem;
                overflow-y: scroll;
                border-radius: 24px;
                li {
                    width: 30%;
                    &.selected {
                        border: 10px solid #66bf30;
                    }
                }
            }
            .confirm-btn-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: rgba($color: #fff, $alpha: 0.8);
                width: 100%;
                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.6rem 0;
                .confirm-btn {
                    width: 160px;
                    cursor: pointer;
                }
            }
        }
    }
}
@media screen and (max-width: 1439px) {
    .all-view-section {
        .inner {
            .title {
                width: 340px;
            }
            .all-view-container {
                max-height: 500px;
            }
        }
    }
}
@media screen and (max-width: 1300px) {
    .all-view-section {
        .inner {
            .title {
                width: 340px;
            }
            .close-btn {
                margin-bottom: 1rem;
                width: 54px;
            }
            .all-view-container {
                width: 100%;
            }
        }
    }
}
