.novel-section {
    background-color: #fdf2bd;
    width: 100%;
    min-height: 100vh;
    .inner {
        padding: 5.5rem 0 0;
        max-width: 90%;
        .reading-container {
            width: 100%;
            position: relative;
            .reading-book-bg {
                width: 90%;
                margin: 0 auto;
            }
            .novel-contents {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90%;
                gap: 2rem;
                & > * {
                    width: 45%;
                }
                .novel-image {
                    aspect-ratio: 1/1;
                    overflow: hidden;
                    img {
                        object-fit: cover;
                    }
                }
                .novel-text-container {
                    padding: 1rem;
                    aspect-ratio: 1/1;
                    position: relative;
                    p {
                        font-size: 1.25rem;
                        line-height: 2;
                        text-align: center;
                    }
                    .next-page-btn {
                        width: 180px;
                        cursor: pointer;
                        position: absolute;
                        bottom: 10px;
                        right: 0;
                    }
                }
            }
        }
    }
}
