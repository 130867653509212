.selecting-section {
    background-color: #c9ebf7;
    width: 100%;
    min-height: 100vh;
    position: relative;
    // testing
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
    }

    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    // testing
    .inner {
        max-width: 90%;
        padding: 6rem 0;
        position: relative;
        .all-view-btn {
            width: 140px;
            position: absolute;
            right: 0;
            cursor: pointer;
        }
        .selecting-tit {
            width: 200px;
            margin: 0 auto;
        }
        .selecting-container {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 1rem;
            .selecting-box {
                background-color: var(--bs-white);
                padding: 1.25rem 1rem;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 18px;
                min-height: 720px;
                &.left {
                    width: 28%;
                    position: relative;
                    .before-select {
                        .left-title {
                            border-radius: 8px;
                            background: #c9ebf7;
                            color: #32a3e3;
                            padding: 1rem 0;
                            font-size: 1.5rem;
                            font-weight: 900;
                            text-align: center;
                        }
                        .left-desc {
                            line-height: 2;
                            text-align: center;
                            margin: 2rem 0;
                            font-size: 28px;
                            font-weight: 100;
                            span {
                                color: #4881e1;
                                font-weight: 900;
                            }
                        }
                        .char {
                            width: 240px;
                            margin: 0 auto;
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            transform: translateX(-50%);
                        }
                    }
                    .selected-story-detail {
                        .selected-story-detail-cover {
                            position: relative;
                            border-radius: 0px 6.448px 6.448px 0px;
                            overflow: hidden;
                            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                            width: 80%;
                            margin: 0 auto;
                            .selected-story-title {
                                position: absolute;
                                bottom: 30px;
                                left: 50%;
                                transform: translateX(-50%);
                                color: var(--bs-white);
                                text-align: center;
                                font-size: 1.8rem;
                                text-shadow: 0px 3.095px 6.191px
                                    rgba(0, 0, 0, 0.73);
                                font-weight: 700;
                            }
                            .book-shadow {
                                position: absolute;
                                top: 0;
                                left: 0;
                                background: rgba(0, 0, 0, 0.3);
                                width: 12px;
                                height: 100%;
                            }
                            .book-white {
                                border-radius: 1.612px;
                                background: #fff;
                                box-shadow: 0px 1.29px 1.29px 0px
                                    rgba(0, 0, 0, 0.25);
                                width: 90%;
                                height: 10px;
                                position: absolute;
                                bottom: 10px;
                                right: 6px;
                            }
                        }
                        .selected-story-plot-title {
                            font-weight: 900;
                            text-align: center;
                            margin-top: 2rem;
                        }
                        .selected-story-plot {
                            text-align: center;
                            line-height: 1.6;
                            font-size: 1.15rem;
                            margin: 0.8rem 0;
                            font-size: 1.25rem;
                        }
                        .plot-more-view {
                            &.expanded {
                                background-color: #858585;
                                &:hover {
                                    background-color: #474747;
                                }
                            }
                            font-family: 'Maple';
                            border: none;
                            background: none;
                            cursor: pointer;
                            font-size: 16px;
                            cursor: pointer;
                            padding: 1rem 2rem;
                            background: #3d76f0;
                            color: #ffffff;
                            -webkit-text-stroke-width: 3.8795416355133057;
                            -webkit-text-stroke-color: #fff;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            border-radius: 55px;
                            border: 4px solid #fff;
                            box-shadow: 0px 1.29px 1.29px 0px #d5d5d5;
                            transition-duration: 0.3s;
                            display: flex;
                            justify-content: center;
                            width: 50%;
                            margin: 0 auto;
                            &:hover {
                                background: #235ad0;
                            }
                        }
                        .button-group {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 0.5rem;
                            width: 100%;
                            margin-top: 1rem;
                            button {
                                font-family: 'Maple';
                                border: none;
                                background: none;
                                cursor: pointer;
                                font-size: 18px;
                                cursor: pointer;
                                &.make-btn {
                                    padding: 1rem 2rem;
                                    background: #00aa7b;
                                    color: #ffffff;
                                    -webkit-text-stroke-width: 3.8795416355133057;
                                    -webkit-text-stroke-color: #fff;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    border-radius: 55px;
                                    border: 4px solid #fff;
                                    box-shadow: 0px 1.29px 1.29px 0px #d5d5d5;
                                    transition-duration: 0.3s;
                                    &:hover {
                                        background: #019a6e;
                                    }
                                }
                                &.cancel-btn {
                                    padding: 1rem 2rem;
                                    background: #d5d5d5;
                                    color: #ffffff;
                                    -webkit-text-stroke-width: 3.8795416355133057;
                                    -webkit-text-stroke-color: #fff;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    border-radius: 55px;
                                    border: 4px solid #fff;
                                    box-shadow: 0px 1.29px 1.29px 0px #d5d5d5;
                                    transition-duration: 0.3s;
                                    &:hover {
                                        background: #b5b4b4;
                                    }
                                }
                            }
                        }
                    }
                }
                &.right {
                    width: 70%;
                    padding-bottom: 1rem;
                    .box-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        p {
                            color: #525252;
                            font-size: 1.15rem;
                        }
                        select {
                            font-family: 'Maple';
                            font-size: 16px;
                            padding: 10px 26px 10px 10px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            background: url('../../../public/images/utils/polygon.svg')
                                no-repeat right 10px center;
                            background-size: 12px;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            cursor: pointer;
                            color: #d9d9d9;
                        }
                    }
                    .box-conts {
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 1.25rem;
                        flex-wrap: wrap;
                        .book-cover {
                            // width: 23%;
                            // flex-shrink: 0;
                            flex: 0 0 calc((100% - (3 * 1.25rem)) / 4);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1439px) {
    .selecting-section {
        .inner {
            padding: 6rem 0 4rem;
            .selecting-container {
                .selecting-box {
                    &.right {
                        .box-conts {
                            .book-cover {
                                flex: 0 0 calc((100% - (2 * 1.25rem)) / 3);
                            }
                        }
                    }
                    &.left {
                        padding-bottom: 1.25rem;
                        padding-bottom: 1.25rem;
                        .before-select {
                            .left-desc {
                                font-size: 24px;
                                margin: 1rem 0;
                            }
                            .char {
                                width: 260px;
                            }
                        }
                        .selected-story-detail {
                            .plot-more-view {
                                width: 100%;
                            }
                            .button-group {
                                flex-direction: column-reverse;
                                button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .selecting-section {
        .inner {
            padding: 6rem 0 4rem;
            .selecting-tit {
                width: 180px;
            }
            .selecting-container {
                .selecting-box {
                    &.left {
                        padding-bottom: 1.25rem;
                        .before-select {
                            .left-title {
                                font-size: 1.2rem;
                                line-height: 1.4;
                            }
                            .left-desc {
                                font-size: 20px;
                            }
                            .char {
                                width: 180px;
                            }
                        }
                        .selected-story-detail {
                            .button-group {
                                flex-direction: column-reverse;
                                button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &.right {
                        .box-conts {
                            .book-cover {
                                flex: 0 0 calc((100% - (1 * 1.25rem)) / 2);
                            }
                        }
                    }
                }
            }
        }
    }
}
